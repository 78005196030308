@import '../../styles/variables.css';

.cloudShadowContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}

.fluffyCloudBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    transform: rotateY(180deg);
}

.fluffyCloud {
    fill: var(--color-light-blue);
    animation: float 2.3s infinite linear;
}

.shadow {
    width: 60px;
    height: 16px;
    border-radius: 100%;
    margin-top: 8px;
    background-color: var(--color-light-blue);
    opacity: 0.5;
    animation: change-size 2.3s infinite linear;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-7px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes change-size {
    50% {
        transform: scaleX(.8);
    }
}