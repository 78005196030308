@import '../../styles/variables.css';

.container {
    display: flex;
    align-items: center;
}

.checkbox {
    position: relative;
    width: 18px;
    height: 18px;
}

.input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
        cursor: pointer;
}

.check {
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-dark-blue);
    background-color: none;
    border-radius: 2px;
    pointer-events: none;
    transition: all 250ms cubic-bezier(0.215, 0.610, 0.355, 1);
}

.check::before, .check::after {
    content: "";
    position: absolute;
    display: block;
    border-radius: 1px;
    background-color: none;
    transform: rotate(-45deg);
    transition: all 250ms cubic-bezier(0.215, 0.610, 0.355, 1);
    cursor: pointer;
}

.check::before {
    top: 7px;
    left: 5px;
    width: 2px;
    height: 6px;
}

.check::after {
    top: 8px;
    left: 6px;
    width: 9px;
    height: 2px;
}

.label, .labelChecked {
    display: none;
}

.label:not(:empty), .labelChecked:not(:empty) {
    display: inline-block;
    margin-left: 10px;   
    margin-bottom: 0; 
    font-weight: 400;
    font-size: 14px;
    line-height: 1.17;
    user-select: none;
    cursor: pointer;
}

.label {
    color: var(--color-light-grey);
}

.labelChecked {
    color: var(--color-dark-blue);
}

.input:checked + .check {
    background-color: var(--color-dark-blue);
}

.input:checked + .check::after {
    background-color: var(--color-white);
}

.input:checked + .check::before {
    background-color: var(--color-white);
}