@import '../../styles/variables.css';

.line {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--color-light-blue);
}

.line {
    margin-right: 1px;
}

.load .line:nth-last-child(1) {
    animation: loadingC 0.6s 0.1s linear infinite;
}

.load .line:nth-last-child(2) {
    animation: loadingC 0.6s 0.2s linear infinite;
}

.load .line:nth-last-child(3) {
    animation: loadingC 0.6s 0.3s linear infinite;
}

.load .line:nth-last-child(4) {
    animation: loadingC 0.6s 0.4s linear infinite;
}

.load .line:nth-last-child(5) {
    animation: loadingC 0.6s 0.5s linear infinite;
}

.load .line:nth-last-child(6) {
    animation: loadingC 0.6s 0.6s linear infinite;
}

.load .line:nth-last-child(7) {
    animation: loadingC 0.6s 0.7s linear infinite;
}

@keyframes loadingC {
    0 {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 5px);
    }

    100% {
        transform: translate(0, 0);
    }
}