
.container, .chousenContainer {
    text-align: center;
    line-height: 1.17;
    border: 1px solid #B3C9E6;
    border-radius: 4px;
    cursor: pointer;
}

.chousenContainer {
    border: 2px solid var(--color-light-blue); 
}

.header {
    padding-bottom: 9px;
}

.name {
    padding: 24px 10px 6px; 
    font-weight: 500;
    font-size: 16px;
    color: var(--color-dark-blue)
}

.price {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-weight: 500;
    font-size: 24px;
    color: var(--color-light-blue);
}

.pricePeriod {
    position: absolute;
    left: 100%;
    bottom: 3px;
    font-weight: 400;
    font-size: 10px;
    color: var(--color-dark-blue);
}

.planBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: 40px;
    font-weight: 700;
    font-size: 18px;
    color: rgb(179, 201, 230);
}

.chousenHeader {
    background-color: var(--color-light-blue);
    padding-bottom: 8px;
}

.chousenHeader *,
.chousenHeader *::before,
.chousenHeader *::after {
  color: var(--color-white);
}

.chousenPlanDescription *,
.chousenPlanDescription *::before,
.chousenPlanDescription *::after {
  color: var(--color-dark-blue);
}
