.logoBox {
    border-radius: 50%;
    overflow: hidden;
}

.logo {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
}