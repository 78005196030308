@import '../../styles/variables.css';

.container {
    position: relative;
    width: 300px;
    padding: 24px 0;
}

.logoutHeader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 70px;
    width: 100%;
    border-bottom: 1px solid #004AAD;
}

.header {
    position: relative;
    z-index: 2;
    display: flex;
    padding: 0 24px 30px;
}

.userInfo {
    padding: 14px 0 0 16px;
    width: 70%;
}

.userName {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 8px;
    color: var(--color-dark);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.userAccountTitle {
    color: var(--color-dark);
    margin-right: 5px;
}

.greenLicenses {
    margin-right: 5px;
    color: #27AE60;
}

.redLicenses {
    margin-right: 5px;
    color: #E93D3D;
}

.userNameLicenses {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.17;
    color: rgb(113, 113, 113);
}


.button {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 8px 8px 8px 24px;
    border-top: 1px solid rgba(179, 201, 230, 0.4);
    border-bottom: 1px solid rgba(179, 201, 230, 0.4);
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    cursor: pointer;
    transition: all 250ms cubic-bezier(0.215, 0.610, 0.355, 1);
}

.button:not(:last-of-type) {
    margin-bottom: 8px;
}

.button:hover {
    background-color: #E5E5E5;;
}

.buttonText {
    white-space: nowrap;
    /* display: flex;
    flex-wrap: nowrap; */
    overflow-x: hidden;
    margin-left: 9px;
}

.unsubscribe {
    color: black;
}

.upgradeButton {
    width: fit-content;
    padding-top: 35px;
    margin: 0 auto;
}

.logOutButton {
    position: absolute;
    top: -10px;
    right: 10px;
    cursor: pointer;
    transition: all 150ms linear;
}

.logOutButton:hover {
    transform: scale(1.05)
}


.settingsButton  {
    display: flex;
    justify-content: center;
    padding-top: 16px;
}

.popoverText { 
    position: relative;  
    padding: 8px 28px 8px 47px; 
    font-weight: 500;
    font-size: 14px;
    line-height: 1.17;
    color: rgb(33, 150, 83);
}

.popoverText::before, 
.popoverText::after {
    content: '';
    position: absolute;
    display: block;
    background-color: #27AE60;
    border-radius: 1px;
}

.popoverText::before {
    left: 20px;
    top: 17px;
    height: 2px;
    width: 8px;
    transform: rotate(50deg)
}

.popoverText::after {
    left: 24px;
    top: 15px;
    height: 2px;
    width: 16px;
    transform: rotate(-50deg)
}