.logoBox {
    width: 140px;
    height: 40px;
}

.logo {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
}